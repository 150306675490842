<template>
  <div>
    <van-pull-refresh
      v-if="list.length"
      v-model="isPullRefreshing"
      class="plan-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="idx"
          :class="['plan-list__rows', idx === list.length - 1 && 'border-none']"
          :border="false"
          @click="gotoDetails(item)"
        >
          <p class="title">{{ item.planName }}</p>
          <div class="intro inspect-record">
            <div class="field field-left">
              <van-icon
                class-prefix="iconfont"
                class="list-icon"
                color="#aeb3c0"
                name="quyu1"
              />
              <span>{{ item.checkedDepartmentName }}</span>
            </div>
            <div class="field field-right">
              <van-icon
                class-prefix="iconfont"
                class="list-icon"
                color="#aeb3c0"
                name="ico-timepoint"
              />
              <span>{{ item.checkTime }}</span>
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <box-empty v-else style="height: calc(100vh - 58px - 44px)"></box-empty>
  </div>
</template>

<script>
import { getPageInspectRecords } from "@/api/psm/plan";
import BoxEmpty from "@/components/BoxEmpty";

export default {
  name: "InspectRecord",
  components: { BoxEmpty },
  props: {
    planInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      queryInfo: {
        planName: "",
        planId: "",
        checkedDepartment: "",
        checkUserId: "",
        orgCode: "",
        department: "",
        page: 1,
        size: 10
      },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  methods: {
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        this.queryInfo.planId = this.planInfo.id;
        const { list, total } = await getPageInspectRecords(this.queryInfo);
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoDetails({ id }) {
      this.$router.push({
        name: "InspectEdit",
        params: { id, planId: this.planInfo.id }
      });
    }
  }
};
</script>

<style scoped></style>
