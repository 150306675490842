<template>
  <div class="plan-detail">
    <van-nav-bar
      left-arrow
      :title="planInfo.name"
      @click-left="$router.back()"
    />
    <van-tabs
      v-model="activeName"
      animated
      swipeable
      sticky
      @change="onActiveChange"
    >
      <van-tab title="基本信息" name="1">
        <plan-info :plan-info="planInfo" :show-execute-btn="showExecuteBtn" />
      </van-tab>
      <van-tab title="排查记录" name="2">
        <inspect-record ref="inspect-record" :plan-info="planInfo" />
      </van-tab>
    </van-tabs>
    <div
      v-show="showExecuteBtn"
      class="details-page__footer-button-wrapper--fixed"
    >
      <van-button
        block
        round
        type="info"
        @click="$router.push({ name: 'InspectAdd', params: { planId: id } })"
        >排查</van-button
      >
    </div>
  </div>
</template>

<script>
import { getInfoPlansById, authExecuteForPlan } from "@/api/psm/plan";
import PlanInfo from "@/views/plans/components/PlanInfo";
import InspectRecord from "@/views/plans/components/InspectRecord";
import "./styles/list.scss";
export default {
  name: "PlanDetail",
  components: { InspectRecord, PlanInfo },
  props: ["id"],
  data() {
    return {
      activeName: "1",
      planInfo: {},
      executeAuth: false
    };
  },
  beforeRouteLeave(to, form, next) {
    if (to.name === "PlanList") {
      this.activeName = "1";
    }
    next();
  },
  computed: {
    showExecuteBtn() {
      return (
        +this.activeName === 1 &&
        !!this.executeAuth &&
        // +this.planInfo.itemType !== 1 &&
        +this.planInfo.status === 0
      );
    }
  },
  async activated() {
    await this.getInfo();
    await this.onActiveChange(this.activeName);
    await this.getExecAuth();
  },
  methods: {
    async getInfo() {
      try {
        this.planInfo = await getInfoPlansById(this.id);
      } catch (e) {
        console.log(e);
      }
    },
    async getExecAuth() {
      try {
        this.executeAuth = await authExecuteForPlan(this.planInfo.id);
      } catch (e) {
        console.log(e);
      }
    },
    async onActiveChange(name) {
      await this.$nextTick();
      if (name === "2") {
        this.$refs["inspect-record"].onRefresh();
      }
    }
  }
};
</script>
